<template>
<LayoutGuest>
  <div class="container">
    <div class="row" id="body-sidemenu">
      <!-- MAIN -->
      <div id="main-content" class="col">
        <div class="row mt-3">
          <div class="col-12 col-md-6 col-lg-4 offset-md-3 offset-lg-4">
            <Notif />
            <Form @submit="login"
              :validation-schema="schema"
              v-slot="{ errors }"
              method="POST"
              class="form-signin needs-validation">
              <h1 class="h5 mb-3 font-weight-normal text-center text-uppercase mb-4">
                Login
              </h1>

              <div class="form-group">
                <label for="email"
                  >Email: <span class="text-danger">*</span></label
                >
                <Field
                  type="text"
                  class="form-control"
                  id="email"
                  placeholder="Masukkan alamat email..."
                  v-model="state.form.email"
                  name="email"
                  :class="{ 'is-invalid': errors.email }"
                />
                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">{{ errors.email }}</div>
              </div>
              <div class="form-group">
                <label for="pass1"
                  >Password: <span class="text-danger">*</span></label
                >
                <Field
                  type="password"
                  class="form-control"
                  id="password"
                  name="password"
                  placeholder="Masukkan password..."
                  v-model="state.form.password"
                  :class="{ 'is-invalid': errors.password }"
                />
                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">{{ errors.password }}</div>
              </div>

              <button type="submit" class="btn btn-main btn-block mt-4" :disabled="state.loading">
                <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" v-if="state.loading"></span>
                MASUK
              </button>
              <router-link
                to="/forgot-password"
                class="btn btn-link btn-block mt-4"
                >Lupa Password?</router-link
              >
              <router-link to="/register" class="btn btn-link btn-block"
                >Belum punya akun?</router-link
              >
              <router-link
                to="/"
                class="btn btn-link btn-block"
                type="submit"
                title="Kambali"
                >Kembali</router-link
              >
            </Form>
          </div>
        </div>
      </div>
      <!-- #main-content -->
    </div>
    <!-- .row -->
  </div>
  <!-- .container -->
</LayoutGuest>
</template>
<script>
import { reactive, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import Notif from '../../helpers/Notif.vue';
import LayoutGuest from '../../components/guest/Layout.vue';

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const state = reactive({
      form: {
        email: "",
        password: "",
      },
      loading: true,
    });
    //start validate
    const schema = Yup.object().shape({
      email: Yup.string()
        .required("Email tidak boleh kosong")
        .email("Email harus berupa alamat surel"),
      password: Yup.string()
        .min(6, "Password sedikitnya mengandung 6 karakter.")
        .required("Password tidak boleh kosong"),
    });

    
    const login = () => {
      state.loading = true;
      store.dispatch("auth/login", state.form).then(
        async () => {
          await store.dispatch("auth/isAdmin");
          if (store.state.auth.authenticated) {
            if (store.state.auth.role === "seller") {
              if(store.state.auth.first){
                router.push({ path: "/jual/produk" });
              }else{
                router.push({ path: "/jual/pesanan-group" });
              }
            } else if (store.state.auth.role === "admin") {
              router.push({ path: "/admin/dashboard" });
            }
          }
        },
        () => {
          //error
          state.loading = false;
          let ret = [];
          ret.push('Email / Password salah!');
          store.dispatch('error/setError', ret);
        }
      );
    };
    onMounted(() => {
      store.dispatch("error/setDefault");
      //if login
      if (store.state.auth.authenticated) {
        if (store.state.auth.role === "seller") {
          router.push({ path: "/jual/pesanan-group" });
        } else if (store.state.auth.role === "admin") {
          router.push({ path: "/admin/dashboard" });
        }
      }
      state.loading = false;
    });
    
    return {
      state,
      schema,
      login
    };
  },
  components: {
    Form,
    Field,
    Notif,
    LayoutGuest,
  }
};
</script>